const featureExemple = true
const featureVariantExemple = {
  old: false,
  new: true,
}

const assessmentHistory = {
  v1: true,
  v2: false
}

export const featuresConfig = {
  featureExemple,
  featureVariantExemple,
  assessmentHistory
}

export const management = 'management'
// [permissions]
export const management_permissions = 'managements.permissions'

// [edit]
export const management_groups_edit = 'managements.groups.edit'
export const management_users_edit = 'managements.users.edit'
export const management_permissions_edit = 'managements.permissions.edit'

// [create]

export const management_permissions_creator = 'managements.permissions.creator'

// [delete]
export const management_groups_delete = 'managements.groups.delete'
export const management_permissions_delete = 'managements.groups.delete'
export const management_users_delete = 'managements.groups.delete'

const featureExemple = true
const featureVariantExemple = {
  old: false,
  new: true,
}

const assessmentHistory = {
  v1: false,
  v2: true,
}
const delegation = {
  v1: false,
  v2: true,
}
const lockAssessment = true

export const featuresConfig = {
  featureExemple,
  featureVariantExemple,
  assessmentHistory,
  delegation,
  lockAssessment,
}

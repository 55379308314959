// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accent": "#ecb2f2",
	"bg1": "#f3f6fb",
	"bg2": "#f3f3f3",
	"bg3": "#fee",
	"bgActive": "#fee",
	"bgError": "#feefef",
	"bgInfo": "#eef2fa",
	"bgSuccess": "#edf9f0",
	"bgWarning": "#fff4ec",
	"black": "#000",
	"black1": "#0a0a09",
	"black2": "#09101d",
	"black5": "#09101d",
	"blue1": "#1e52d9",
	"blue4": "#008ffb",
	"blueColor": "#2392b5",
	"blueColor1": "#1e52d9",
	"blueColor2": "#62dfc9",
	"blueColor3": "#17a2b8",
	"blueColor4": "#008ffb",
	"blueColor6": "#bcc1f233",
	"blueColor7": "#3a68de",
	"blueColor8": "#6390f9",
	"blueColor9": "#32b3fc",
	"blueColor13": "#c2e4fd",
	"error_background": "#feefef",
	"error": "#da1414",
	"gray1": "#dadada",
	"gray2": "#9e9e9e",
	"gray3": "#a4a4a4",
	"gray4": "#d3d3d3",
	"grayColor": "#ebeef2",
	"grayColor10": "#dfdcdf",
	"grayColor11": "#f5f5f5",
	"grayColor2": "#eee",
	"grayColor3": "#6d7580",
	"grayColor4": "#ddd",
	"grayColor5": "#f2f2f2",
	"grayColor6": "#73757d",
	"grayColor7": "#374957",
	"grayColor8": "#ececec",
	"grayColor9": "#c4c4c4",
	"grayColor12": "#7a7a7a",
	"green3": "#19ab4f",
	"greenColor": "#0ab057",
	"greenColor1": "#12c130",
	"greenColor10": "#f1faf4",
	"greenColor11": "#0c9e42",
	"greenColor12": "#26da9e",
	"greenColor13": "#12bb7e",
	"greenColor14": "#47cf86",
	"greenColor15": "#49cd76",
	"greenColor2": "#08864a",
	"greenColor3": "#19ab4f",
	"greenColor4": "#027932",
	"greenColor5": "#00e396",
	"greenColor6": "#067d29",
	"greenColor7": "#c7eed3",
	"greenColor8": "#6bdef8",
	"greenColor9": "#e5f9ff",
	"greenLighten1": "#e6ffe6",
	"greenSuccessBackground": "#dafce0",
	"info_background": "#eef2fa",
	"info": "#2e5aac",
	"neutral": "#fff",
	"neutral1": "#2c3a4b",
	"neutral10": "#f4f6f9",
	"neutral11": "#dadada",
	"neutral12": "#fafafa",
	"neutral13": "#fff3f7",
	"neutral2": "#394452",
	"neutral3": "#545d69",
	"neutral4": "#6d7580",
	"neutral5": "#858c94",
	"neutral6": "#a5abb3",
	"neutral7": "#dadee3",
	"neutral8": "#ebeef2",
	"neutral9": "#f4f6f9",
	"neutralBlack": "#09101d",
	"orangeColor": "#fb9217",
	"orangeColor2": "#ffb55e",
	"orangeColor3": "#f97e57",
	"orangeColor4": "#fc9332",
	"orangeColor5": "#ffa012",
	"orangeLighten1": "#f9f2df",
	"pink2": "#ff367e",
	"pinkColor1": "#fc4a69",
	"pinkColor2": "#ff367e",
	"pinkColor3": "#ff739d",
	"pinkColor4": "#be63f9",
	"pinkColor5": "#ec78ff",
	"pinkColor6": "#db2fbf",
	"primary": "#dd0041",
	"primaryFaded": "#fee",
	"purple1": "#9e23fe",
	"purple2": "#be0c97",
	"purple3": "#ae2fdb",
	"redColor": "#e71616",
	"redColor1": "#ed3126",
	"redColor10": "#ffa7a7",
	"redColor2": "#ef5d5d",
	"redColor5": "#ffe6e6",
	"redColor7": "#b82505",
	"redColor8": "#ffd2da",
	"redColor9": "#ffe5e5",
	"redColor11": "#e42e62",
	"redColor12": "#f66767",
	"redColor13": "#f66363",
	"redError": "#e03f3f",
	"redErrorBackground": "#fcdada",
	"redLighten1": "#fddddd",
	"redLighten2": "#fee",
	"redLighten3": "#fff3f7",
	"redLighten4": "#dfdfdf",
	"secondary": "#fdcc4a",
	"sliderTrack1": "#eb235e",
	"sliderTrack2": "#eda008",
	"sliderTrack3": "#23ebb0",
	"statusError": "#da1414",
	"statusInfo": "#2e5aac",
	"statusNeutral": "#a5abb3",
	"statusSuccess": "#287d3c",
	"statusWarning": "#b95000",
	"success_background": "#edf9f0",
	"success": "#287d3c",
	"treemapColors": "#a288d1,#aaa85b,#d26d98,#3d9a2c,#713733,#87bb29,#c78d97,#dd3f29,#508c73,#eb4d14,#5691a5,#cf6a2e,#397043,#ae4ded,#c69a6d,#512198,#476023,#d73d85,#3f4222,#6c38cd,#aa8b6e,#766beb,#92ab6b,#56327e,#cdb623,#67344e,#4acd2c,#b785a6,#50cc59,#48486c,#d03e3b,#6e9acb,#873519,#49bcb1,#d9764c,#43bcd8,#da745e,#6481e3,#8aa679,#5854bb,#cb8675,#d640d2,#745127,#d542ac,#938d2c,#45538c,#e28723,#ab8bba,#a6681a,#7c2b65,#88b144,#882541,#5abc68,#c770bc,#76641f,#b664d3,#49be8a,#dc3562,#5694da,#d5a24e,#d37581,#bea732,#bd5155,#508633,#c1884d",
	"violet": "#775dd0",
	"warning": "#b95000",
	"warning_background": "#fff4ec",
	"white": "#fff",
	"yellow6": "#feb323",
	"yellowColor": "#fbad17",
	"yellowColor1": "#fbad17",
	"yellowColor2": "#ffba33",
	"yellowColor3": "#ffd953",
	"yellowColor4": "#ffc350",
	"yellowColor5": "#f6931e",
	"yellowColor6": "#feb323",
	"yellowColor7": "#fabb33",
	"yellowColor8": "#fccb1c",
	"yellowColor9": "#f6c61e",
	"yellowColor10": "#f9b732",
	"yellowLighten1": "#fffdd6"
};
export default ___CSS_LOADER_EXPORT___;

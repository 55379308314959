export const profile = 'profile'
export const profile_home_custom_survey = 'profile.home.custom-survey'

export const profile_opinions = 'profile.opinions'
export const profile_feedback = 'profile.feedback'
export const profile_feedback_sent = 'profile.feedback.sent'
export const profile_feedback_received = 'profile.feedback.received'
export const profile_feedback_requested = 'profile.feedback.requested'
export const profile_reviews = 'profile.reviews'

export const profile_growth = 'profile.growth'
export const profile_growth_report = 'profile.growth.report'
export const profile_growth_report_evaluation_results =
  'profile.growth.report.evaluation-results'
export const profile_growth_report_evaluation_results_history =
  'profile.growth.report.evaluation-results-history'
export const profile_growth_report_essences = 'profile.growth.report.essences'
export const profile_growth_report_essences_scale =
  'profile.growth.report.essences.scale'
export const profile_growth_report_essences_advanced_report =
  'profile.growth.report.essences.advanced-report'
export const profile_growth_report_learning_agility =
  'profile.growth.report.learning-agility'
export const profile_growth_report_learning_agility_advanced_report =
  'profile.growth.report.learning-agility.advanced-report'
export const profile_growth_report_swot = 'profile.growth.report.swot'
export const profile_growth_report_custom = 'profile.growth.report.custom'
export const profile_growth_personal_development_plans =
  'profile.growth.personal-development-plans'
export const profile_growth_personal_development_plans_access =
  'profile.growth.personal-development-plans.access'
export const profile_growth_personal_development_plans_delete =
  'profile.growth.personal-development-plans.delete'
export const profile_growth_personal_development_plans_create =
  'profile.growth.personal-development-plans.create'
export const profile_growth_personal_development_plans_edit =
  'profile.growth.personal-development-plans.edit'
export const profile_growth_personal_development_plans_fetch =
  'profile.growth.personal-development-plans.fetch'
export const profile_growth_assessments_self_evaluation =
  'profile.growth.assessments.self-evaluation'
export const profile_growth_assessments_leadership_evaluation =
  'profile.growth.assessments.leadership-evaluation'
export const profile_feedback_received_request_feedback =
  'profile.feedback.received.request-feedback'
export const profile_feedback_sent_sent_feedback =
  'profile.feedback.sent.sent-feedback'
export const profile_growth_invites = 'profile.growth.invites'
export const profile_growth_invites_my_invitations =
  'profile.growth.invites.my-invitations'
export const profile_growth_invites_received_invitations =
  'profile.growth.invites.received-invitations'
export const profile_growth_invites_received_invitations_accept =
  'profile.growth.invites.received-invitations.accept'
export const profile_growth_invites_received_invitations_accept_initiate =
  'profile.growth.invites.received-invitations.accept.initiate'
export const profile_growth_invites_received_invitations_reject =
  'profile.growth.invites.received-invitations.reject'
export const profile_growth_invites_my_invitations_invite =
  'profile.growth.invites.my-invitations.invite'
export const profile_growth_invites_my_invitations_delete =
  'profile.growth.invites.my-invitations.delete'
export const profile_home_growth_invites_my_invitations =
  'profile.home.growth.invites.my-invitations'
export const profile_home_growth_invites_shared_assessment_reject =
  'profile.home.growth.invites.shared-assessment.reject'
export const profile_home_engagement_survey = 'profile.home.engagement.survey'
export const profile_home_engagement_opinions_send =
  'profile.home.engagement.opinions.send'
export const profile_home_engagement_opinions =
  'profile.home.engagement.opinions'
export const profile_home_engagement_opinions_group =
  'profile.home.engagement.opinions.group'
export const profile_home_engagement_opinions_group_report =
  'profile.home.engagement.opinions.group.report'
export const profile_home_engagement_action_plans =
  'profile.home.engagement.action-plans'
export const profile_home_growth_assessments_self_evaluation =
  'profile.home.growth.assessments.self-evaluation'
export const profile_home_growth_assessments_followup =
  'profile.home.growth.assessments.followup'
export const profile_home_growth_assessments_leadership_evaluation =
  'profile.home.growth.assessments.leadership-evaluation'
export const profile_home_growth_assessments_received_invitations =
  'profile.home.growth.assessments.received-invitations'
export const profile_home_growth_assessments_my_assessments =
  'profile.home.growth.assessments.my-assessments'
export const profile_home_growth_feedbacks = 'profile.home.growth.feedbacks'
export const profile_home_growth_pdps = 'profile.home.growth.pdps'
export const profile_home_growth_1on1 = 'profile.home.growth.1on1'
export const profile_home_performance_my_okrs =
  'profile.home.performance.my-okrs'
export const profile_home_performance_dashboard =
  'profile.home.performance.dashboard'
export const profile_home_performance_initiatives =
  'profile.home.performance.initiatives'
export const profile_home_performance_okrs_tree =
  'profile.home.performance.okrs-tree'
export const profile_growth_invites_shared_assessments =
  'profile.growth.invites.shared-assessments'
export const profile_growth_invites_shared_assessments_reject =
  'profile.growth.invites.shared-assessments.reject'
export const profile_growth_invites_received_invitations_shared_assessments_accept =
  'profile.growth.invites.received-invitations.shared-assessments.accept'
export const profile_growth_invites_received_invitations_shared_assessments_reject =
  'profile.growth.invites.received-invitations.shared-assessments.reject'

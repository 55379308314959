// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[4]!../../../node_modules/shepherd.js/dist/css/shepherd.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".shepherd-button{padding:.5rem .75rem;border-radius:.375rem;font-size:.75rem!important;font-weight:600;line-height:117.5%}.shepherd-button,.shepherd-button:not(:disabled):hover{background:#dd0041;color:#fff}.shepherd-button.shepherd-button-secondary{background:#fff;font-size:.875rem!important;color:#858c94;font-weight:400;line-height:117.5%}.shepherd-button.shepherd-button-secondary:not(:disabled):hover{background:#fff;color:#394452}.shepherd-footer{padding:0;border-bottom-left-radius:.3125rem;border-bottom-right-radius:.3125rem}.shepherd-cancel-icon{font-size:2rem!important;color:#394452;font-weight:400}.shepherd-cancel-icon:hover{color:#2c3a4b}.shepherd-has-title .shepherd-content .shepherd-cancel-icon{color:#394452}.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover{color:#2c3a4b}.shepherd-title{font-size:1rem!important;color:#2c3a4b;font-weight:600;line-height:1.5rem}.shepherd-header{border-top-left-radius:.3125rem;border-top-right-radius:.3125rem}.shepherd-has-title .shepherd-content .shepherd-header{background:#fff;padding:0}.shepherd-text{font-size:1rem!important;color:#2c3a4b;font-weight:400;line-height:1.5rem;padding:0}.shepherd-content{border-radius:.3125rem;padding:.75em;display:flex;flex-direction:column;gap:.5rem}.shepherd-element{background:#fff;border-radius:.5625rem;box-shadow:0 .25rem .5625rem 0 rgba(0,0,0,.05)!important;max-width:25rem}.shepherd-arrow,.shepherd-arrow:before{height:1rem;width:1rem}.shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before{background-color:transparent}.shepherd-modal-overlay-container path{fill:#0908081a;-webkit-backdrop-filter:blur(.25rem);backdrop-filter:blur(.25rem)}.shepherd-modal-overlay-container.shepherd-modal-is-visible{opacity:1}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

export const growth = 'growth'
export const growth_feedbacks_received = 'growth.feedbacks.received'
export const growth_assessments = 'growth.assessments'
export const growth_assessments_report = 'growth.assessments.report'
export const growth_assessments_report_swot = 'growth.assessments.report.swot'
export const growth_assessments_report_custom =
  'growth.assessments.report.custom'
export const growth_assessments_compare = 'growth.assessments.compare'
export const growth_assessments_participation =
  'growth.assessments.participation'
export const growth_assessments_report_calibration_report =
  'growth.assessments.report.calibration-report'
export const growth_assessments_followup = 'growth.assessments.followup'
export const growth_assessments_followup_evaluation =
  'growth.assessments.followup.evaluation'
export const growth_assessments_followup_calibration =
  'growth.assessments.followup.calibration'
export const growth_assessments_followup_calibration_leader =
  'growth.assessments.followup.calibration.leader'
export const growth_assessments_followup_initiate =
  'growth.assessments.followup.initiate'
export const growth_assessments_followup_conclude =
  'growth.assessments.followup.conclude'
export const growth_assessments_followup_reopen =
  'growth.assessments.followup.reopen'
export const growth_assessments_followup_share =
  'growth.assessments.followup.share'
export const growth_assessments_followup_publish =
  'growth.assessments.followup.publish'
export const growth_assessments_followup_unpublish =
  'growth.assessments.followup.unpublish'
export const growth_assessments_evaluation = 'growth.assessments.evaluation'
export const growth_assessments_report_evaluation_results =
  'growth.assessments.report.evaluation-results'
export const growth_assessments_report_evaluation_results_history =
  'growth.assessments.report.evaluation-results-history'
export const growth_assessments_report_essences =
  'growth.assessments.report.essences'
export const growth_assessments_report_essences_advanced_report =
  'growth.assessments.report.essences.advanced-report'
export const growth_assessments_report_learning_agility =
  'growth.assessments.report.learning-agility'
export const growth_assessments_report_learning_agility_advanced_report =
  'growth.assessments.report.learning-agility.advanced-report'
export const growth_assessments_invites_received_invitations_accept =
  'growth.assessments.invites.received-invitations.accept'
export const growth_assessments_invites_received_invitations_reject =
  'growth.assessments.invites.received-invitations.reject'
export const growth_assessments_invites = 'growth.assessments.invites'
export const growth_assessments_invites_my_invitations =
  'growth.assessments.invites.my-invitations'
export const growth_assessments_invites_received_invitations =
  'growth.assessments.invites.received-invitations'
export const growth_assessments_invites_my_invitations_invite =
  'growth.assessments.invites.my-invitations.invite'
export const growth_assessments_invites_my_invitations_delete =
  'growth.assessments.invites.my-invitations.delete'

export const growth_assessments_invites_group_invitations =
  'growth.assessments.invites.group-invitations'
export const growth_assessments_invites_group_invitations_delete =
  'growth.assessments.invites.group-invitations.delete'
export const growth_assessments_invites_group_invitations_invite =
  'growth.assessments.invites.group-invitations.invite'

export const growth_assessments_invites_group_received_invitations =
  'growth.assessments.invites.group-received-invitations'
export const growth_assessments_invites_group_received_invitations_accept =
  'growth.assessments.invites.group-received-invitations.accept'
export const growth_assessments_invites_group_received_invitations_reject =
  'growth.assessments.invites.group-received-invitations.reject'

export const growth_assessments_report_essences_scale =
  'growth.assessments.report.essences.scale'

export const growth_1on1 = 'growth.1on1'
export const growth_1on1_next_meetings = 'growth.1on1.next-meetings'
export const growth_1on1_previous_meetings = 'growth.1on1.previous-meetings'
export const growth_1on1_schedule_meetings = 'growth.1on1.schedule-meeting'
export const growth_1on1_close = 'growth.1on1.close'
export const growth_assessments_report_export_xlsx =
  'growth.assessments.report.export.xlsx'
export const growth_assessments_followup_export_xlsx =
  'growth.assessments.followup.export.xlsx'
export const growth_personal_development_plans =
  'growth.personal-development-plans'
export const growth_personal_development_plans_fetch =
  'growth.personal-development-plans.fetch'
export const growth_personal_development_plans_delete =
  'growth.personal-development-plans.delete'
export const growth_personal_development_plans_edit =
  'growth.personal-development-plans.edit'
export const growth_personal_development_plans_create =
  'growth.personal-development-plans.create'
export const growth_assessments_followup_transfer =
  'growth.assessments.followup.transfer'
export const growth_assessments_followup_assessment_delete =
  'growth.assessments.followup.assessment.delete'
export const growth_feedback_teams = 'growth.feedback.teams'
export const growth_feedback_request_feedback =
  'growth.feedback.request.feedback'

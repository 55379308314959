import Vue from 'vue'
import { FeatureToggleComponent } from 'vue-feature-toggle'
import { featuresConfig } from '../helpers/features'

const FeatureToggle = {
  install(Vue) {
    Object.entries(featuresConfig).forEach(([featureKey, featureValue]) => {
      if (
        typeof featureValue === 'boolean' ||
        typeof featureValue === 'function'
      ) {
        FeatureToggleComponent.visibility(featureKey, featureValue)
      } else {
        Object.entries(featureValue).forEach(([variantKey, variantValue]) => {
          FeatureToggleComponent.visibility(
            featureKey,
            variantKey,
            variantValue
          )
        })
      }
    })

    Vue.component('Feature', FeatureToggleComponent)

    Vue.prototype.$feature = FeatureToggleComponent
  },
}

Vue.use(FeatureToggle)

import { projectAuth } from '@/firebase/config'

const calcRouteQuery = ({ l = null, t = null, ...toQuery }) => {
  return {
    loginQuery: {
      ...(l && { l }),
      ...(t && { t }),
    },
    toQuery,
  }
}

export const beforeEachRouterEntry = async (to, _from, next) => {
  const currentUser = projectAuth.currentUser
  const requiresAuth = to.matched.some(record => record?.meta?.requiresAuth)

  const isRedirect = to?.meta?.external
  if (isRedirect) {
    window.location.assign(isRedirect)
    return
  }

  if (requiresAuth && !currentUser && to.name !== 'Login') {
    const { loginQuery, toQuery } = calcRouteQuery(to.query)

    if (!to.path.includes('/login')) {
      sessionStorage.setItem(
        'interface-recovery--redirectPath',
        JSON.stringify({ to, toQuery })
      )
    }

    if (_from.name === 'Login') {
      return
    }

    return next({ name: 'Login', query: loginQuery })
  }

  if (currentUser && to.query?.redirect) {
    if (to.query.redirect === 'feedbacks') {
      const options = {
        'received-feedback': 'MyFeedbacksChatPage',
        'request-feedback': 'SentFeedbacksChatPage',
        'message-feedback': 'SentFeedbacksChatPage',
        'message-request-feedback': 'MyFeedbacksChatPage',
        'request-teams-feedback': 'GroupFeedbacksChatPage',
        'message-teams-feedback': 'GroupFeedbacksChatPage',
      }

      const tabs = {
        'received-feedback': 'received',
        'request-feedback': 'requested',
        'message-feedback': 'sent',
        'message-request-feedback': 'my-requests',
      }

      const routeName = options[to.query?.action]

      if (routeName) {
        const feedbackID = to.query?.id
        const tab = tabs[to.query?.action]

        return next({
          name: routeName,
          ...(feedbackID && {
            params: {
              feedbackID: feedbackID,
            },
          }),
          ...(tab && {
            query: {
              tab: tab,
            },
          }),
        })
      }
    }
  }

  if (!requiresAuth && currentUser) {
    return next({ name: 'Main' })
  }

  return next()
}

export default { beforeEachRouterEntry }
